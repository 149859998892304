import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Mniejsze strony internetowe');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Mniejsze strony internetowe'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={'Mniejsze strony internetowe'}
      />
      <Spacing lg='80' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Małe nie znaczy mniej efektywne.'
          subtitle='Mniejsze strony internetowe'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-6">
              <IconBox
                  icon='/images/landing.png'
                  title='Mniejsze strony internetowe'
                  subtitle='Nasza oferta tworzenia stron typu landing page pozwoli Ci skupić się na efektywnym przekazywaniu przesłania i skutecznym generowaniu konwersji.'
              />
              <Spacing lg='90' md='45'/>
          </Div>
          <Div className="col-lg-6">
              <p>Tworzymy profesjonalne strony landingowe, które są zaprojektowane tak, aby wciągnąć odwiedzających, zachęcić ich do działania i przekształcić ich w potencjalnych klientów.</p>
              <p>Nasze usługi tworzenia stron landingowych obejmują:</p>
              <ol>
                  <li>
                      <p>Indywidualny projekt: Tworzymy unikalne i spersonalizowane projekty, które odpowiadają Twoim celom i markowym wytycznym.</p>
                  </li>
                  <li>
                      <p>Optymalizacja dla urządzeń mobilnych: Wszystkie nasze strony landingowe są w pełni responsywne, co oznacza, że doskonale wyglądają i działają na różnych urządzeniach, takich jak smartfony i tablety.</p>
                  </li>
                  <li>
                      <p>Czysty i minimalistyczny design: Stawiamy na prostotę i czytelność, aby skoncentrować uwagę użytkowników na najważniejszych treściach i CTA (Call-to-Action).</p>
                  </li>
                  <li>
                      <p>Współpraca zespołu: Nasz zespół współpracuje ściśle z Tobą, aby zrozumieć Twoje cele i wymagania, a następnie dostosować projekt strony landingowej do Twoich oczekiwań.</p>
                  </li>
                  <li>
                      <p>Integracja z narzędziami marketingowymi: Pomagamy w integracji strony landingowej z narzędziami do marketingu internetowego, takimi jak Google Analytics czy systemy do automatyzacji marketingu.</p>
                  </li>
                  <li>
                      <p>Testowanie i optymalizacja: Regularnie monitorujemy działanie strony landingowej, przeprowadzamy testy A/B i wprowadzamy poprawki w celu osiągnięcia maksymalnej skuteczności konwersji.</p>
                  </li>
              </ol>
              <p>Zaufaj nam, aby stworzyć atrakcyjne, efektywne i skuteczne strony landingowe, które pomogą Ci w osiągnięciu sukcesu w kampaniach marketingowych i generowaniu leadów. Nasza oferta tworzenia stron landingowych pomoże Ci w przyciąganiu nowych klientów i zwiększeniu konwersji na Twojej stronie.</p>
              <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='80' md='50'/>
      <Div className="container">
        <Cta 
          title='Masz więcej pytań? <br /> Nie zwlekaj dłużej, napisz'
          btnText='Wypełnij formularz kontaktowy'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
