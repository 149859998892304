import React, { useEffect } from 'react'
import { Link }  from 'react-router-dom'
import { pageTitle }        from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function PortfolioDetailsPage() {
  pageTitle('Product Gallery Override');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
      <>
        <PageHeading
            title='Product Gallery Override'
            bgSrc='/images/service_hero_bg.jpeg'
            pageLinkText='Product Gallery Override'
        />
        <Spacing lg='150' md='80'/>
        <Div className="container">
          <img src="/images/galleryproduct_details.png" alt="Details" className="cs-radius_15 w-100" />
          <Spacing lg='90' md='40'/>
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                  title='Product Gallery Override'
                  subtitle='PWA Studio'
              >
                <Spacing lg='40' md='20'/>
                <p>Nasza wtyczka znacząco rozszerza możliwości domyślnej galerii produktowej dostępnej w Venia UI. Teraz Twoje zdjęcia produktów zostaną zaprezentowane w nowoczesnym i atrakcyjnym popupie, który oferuje użytkownikowi doskonałe wrażenia wizualne.</p>
                <Spacing lg='10' md='10'/>
                <p>Dzięki naszej wtyczce, użytkownicy mogą teraz korzystać z funkcji Zoom'a, co pozwala im zbliżyć i dokładnie przyjrzeć się każdemu detelowi na zdjęciu. To wspaniałe narzędzie pozwala na przedstawienie produktów w pełnym detalu, zwiększając zaangażowanie użytkowników i zachęcając ich do dokładniejszego przyjrzenia się oferowanym produktom.</p>
                <Spacing lg='10' md='10'/>
                <p>Dodatkowo, nasza wtyczka umożliwia prosty i intuicyjny przewijanie zdjęć w galerii. Dzięki temu użytkownicy mogą szybko i płynnie przechodzić między kolejnymi zdjęciami, co zapewnia im wygodne i przyjemne przeglądanie wszystkich dostępnych wariantów produktu.</p>
                <Spacing lg='10' md='10'/>
                <p>Dzięki tym nowym funkcjom, Twoja galeria produktowa staje się bardziej interaktywna i atrakcyjna dla klientów, co może wpłynąć na zwiększenie konwersji i sprzedaży. Nasza wtyczka została starannie zaprojektowana, aby zapewnić najwyższą jakość i wydajność, co sprawia, że jest idealnym rozszerzeniem dla Twojego sklepu opartego na Venia UI.</p>
                <Spacing lg='10' md='10'/>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg='60' md='40'/>
              <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Informacje o produkcie</h2>
              <Spacing lg='50' md='30'/>
              <Div className="row">
                <Div className="col-6">
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Kategoria:</h3>
                  <p className='cs-m0'>Karta produktu</p>
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className="col-6">
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Rodzaj:</h3>
                  <p className='cs-m0'>Wtyczka</p>
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className="col-6">
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Kompatybilność:</h3>
                  <p className='cs-m0'>PWA Studio 12.6 do 13.20</p>
                  <Spacing lg='30' md='30'/>
                </Div>
                <Div className="col-6">
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Cena:</h3>
                  <Link to='/contact' className='cs-m0'>Wypełnij formularz</Link>
                  <Spacing lg='30' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg='65' md='10'/>
        </Div>
        <Spacing lg='145' md='80'/>
        <Cta
            title='Jesteś zainteresowany wtyczką <br> napisz na info@mpro.pl'
            bgSrc='/images/cta_bg_2.jpeg'
            variant='rounded-0'
        />
      </>
  )
}
