import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('Dlaczego my?');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="Dlaczego my ?"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Dlaczego my ?"
      />
      {/* End Page Heading Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/why.svg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Wybór jest prostszy niż myślisz."
              subtitle="Dlaczego my?"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Jesteśmy zespołem wykwalifikowanych programistów posiadających wieloletnie doświadczenie w budowie sklepów za pomocą Magento i PWA Studio. Nasza wiedza, umiejętności i praktyczne doświadczenie pozwalają nam tworzyć i wdrażać rozwiązania o najwyższej jakości. Oto dlaczego powinieneś wybrać nasz zespół:
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Wiedza i doświadczenie:</strong> Posiadamy solidne fundamenty w zakresie Magento i PWA Studio. Nasz zespół składa się z doświadczonych programistów, którzy zdobyli gruntowną wiedzę na przestrzeni lat pracy w branży e-commerce.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Doskonałość techniczna:</strong> Nasze umiejętności programistyczne są nieustannie doskonalone, aby pozostać na bieżąco z najnowszymi trendami i najlepszymi praktykami. Wykorzystujemy zaawansowane technologie i narzędzia, aby dostarczać rozwiązania najwyższej jakości.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Specjalizacja w Magento:</strong> Specjalizujemy się w platformie Magento, która jest jedną z najpopularniejszych i najbardziej wszechstronnych platform e-commerce na rynku. Znamy jej możliwości, funkcjonalności i potencjał, co pozwala nam tworzyć sklepy internetowe dostosowane do potrzeb naszych klientów.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Dostosowanie do potrzeb klienta:</strong> Skupiamy się na zrozumieniu potrzeb i celów naszych klientów. Dzięki temu jesteśmy w stanie dostosować nasze rozwiązania, strategie i podejście do indywidualnych wymagań każdego projektu.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Skalowalność i wydajność:</strong> Nasze rozwiązania są skalowalne i zoptymalizowane pod kątem wydajności. Projektujemy sklepy, które są gotowe na rozwój i możliwość obsługi większego ruchu wraz z rozwojem biznesu klienta.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                <strong>Partnerstwo i wsparcie:</strong> Budujemy długoterminowe relacje z naszymi klientami, działając jako partnerzy w ich sukcesie. Oferujemy wsparcie na każdym etapie projektu, zapewniamy regularną komunikację i służymy naszą wiedzą i doświadczeniem.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                Zaufaj nam a z przyjemnością dostarczymy Ci innowacyjne i skuteczne rozwiązania, które pomogą Ci osiągnąć sukces w sprzedaży online.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Cta
          title="Skontaktuj się już dziś,<br> czekamy na Twoją wiadomość."
          btnText="Przejdź do formularza kontaktowego"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
      />
      {/* End CTA Section */}
    </>
  );
}
