import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import './scss/index.scss';

const apiToken = process.env.REACT_APP_DATO_API_TOKEN;
const client = new GraphQLClient({
    url: "https://graphql.datocms.com/",
    headers: {
        "Authorization": "Bearer "+ apiToken,
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ClientContext.Provider value={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ClientContext.Provider>
  </React.StrictMode>
);

