import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServicesPage() {
  pageTitle('Usługi');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Usługi'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Usługi'
      />
      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                  title="Usługi w których możemy ci pomóc"
                  subtitle="W czym możemy ci pomóc"
                  btnText="Pokaż wszystkie usługi"
                  btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Projektowanie makiet"
                      link="/service/mockup"
                      src="/images/service1.svg"
                      alt="Projektowanie makiet"
                      id="1"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Aplikacje internetowe"
                      link="/service/apps"
                      src="/images/service2.svg"
                      alt="Aplikacje internetowe"
                      id="2"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Obsługa istniejących serwisów"
                      link="/service/site-service"
                      src="/images/service3.svg"
                      alt="Obsługa istniejących serwisów"
                      id="3"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Optymalizacja działania sklepu"
                      link="/service/speed-site"
                      src="/images/service4.svg"
                      alt="Optymalizacja działania sklepu"
                      id="4"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Moduły Magento"
                      link="/products"
                      src="/images/service6.svg"
                      alt="Moduły Magento"
                      id="5"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Mniejsze strony - typu wizytówka"
                      link="/service/landing-page"
                      src="/images/service5.svg"
                      alt="Mniejsze strony - typu wizytówka"
                      id="5"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Masz nietypowe zlecenie <br />napisz wykonamy je dla <i>ciebie</i>'
          btnText='Skontaktuj się z nami'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
          variant="rounded-0"
        />
      </Div>
    </>
  )
}
