import React from 'react';
import { pageTitle } from '../../helper';
import Div from '../Div';
import SectionHeading from '../SectionHeading';

export default function ErrorPage() {
  pageTitle('Error');
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg cs-error_page"
      style={{ backgroundImage: 'url("/images/404.svg")' }}
    >
      <Div className="container">
        <SectionHeading
          title="Ohoooo, nie ma to jak znaleźć się w <br/> nieodpowiednim miejscu w nieodpowiednim czasie."
          subtitle="404 Erorrrr"
          btnText="Powrót do strony głównej"
          btnLink="/"
          variant="cs-style1 text-center"
        />
      </Div>
    </Div>
  );
}
