import React          from 'react';
import Pdf            from '../../doc/regulamin.pdf';
import PageHeading    from '../PageHeading';
import Spacing        from '../Spacing';
import Div            from '../Div';
import SectionHeading from '../SectionHeading';
import { Icon }       from '@iconify/react';
import { pageTitle }  from '../../helper';
import { useEffect }  from 'react';

export default function Download() {
    pageTitle('Pobierz');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageHeading
                title="Pobierz"
                bgSrc="/images/contact_hero_bg.jpeg"
                pageLinkText="Pobierz"
            />
            <Spacing lg="150" md="80" />
            <Div className="container">
                <Div className="row">
                    <Div className="col-lg-6">
                        <SectionHeading
                            title="Pobierz pliki"
                            subtitle="Do ciekawskich świat należy."
                        />
                        <Spacing lg="10" md="80" />
                        <span className='cs-accent_color'><Icon icon="material-symbols:download" />
                                    <a href={Pdf} target="_blank" rel="noreferrer">Pobierz regulamin</a>
                        </span>
                    </Div>
                </Div>
            </Div>
            <Spacing lg="50" md="40" />
        </>
    );
}
