import React from 'react'
import { useState } from 'react'
import Div from '../Div'

export default function Accordion() {
  const [selected, setSelected] = useState(0)
  const handelToggle = (index) => {
    if(selected === index) {
      return setSelected(null)
    }
    setSelected(index)
  }
  const accordionData = [
    {
      question: 'Czy mogę zamówić u was prostą stronę internetową?',
      answer: 'Tak, jak najbardziej. Aby dokładnie wycenić projekt i zrozumieć Twoje potrzeby, potrzebujemy informacji na temat liczby zakładek (stron) w Twoim projekcie oraz przykładowych stron, które mają być zawarte na stronie internetowej. Liczba zakładek i zawartość stron są kluczowymi elementami, które wpływają na złożoność projektu, zakres pracy oraz czas realizacji. Proszę podać nam te informacje, a my będziemy mogli dokładniej dostosować naszą wycenę do Twoich indywidualnych wymagań i celów biznesowych.'
    },
    {
      question: 'Jaka jest cena wdrożenia sklepu?',
      answer: 'Cena usługi związanej z tworzeniem lub wdrożeniem sklepu Magento lub PWA Studio zależy od wielu czynników, takich jak zakres projektu, specyficzne wymagania klienta, złożoność projektu i czas realizacji. Zapraszamy do kontaktu w celu uzyskania bezpłatnej konsultacji i dokładnej wyceny, dostosowanej do Twoich potrzeb i celów biznesowych.'
    },
    {
      question: 'Jaki jest czas realizacji projektu?',
      answer: 'Wszystko zależy od zakresu i nakładu pracy, jaki pochłonie Twój projekt. Dokładny termin realizacji staramy się podać w momencie wysłania wyceny, uwzględniając wszystkie indywidualne aspekty i wymagania Twojego projektu.'
    },
    {
      question: 'Czy jesteście w stanie zaprojektować logo, ikony dla projektu?',
      answer: 'Tak, jeśli będziesz potrzebował logo, ikon, banerów, grafik promocyjnych lub innych elementów graficznych, które będą odpowiadać Twojej firmie i pasować do całej koncepcji projektu, możemy je dla Ciebie przygotować. Będziemy współpracować z Tobą, aby zrozumieć Twoje preferencje, styl i wizję, aby stworzyć grafiki, które idealnie oddają to, czego oczekujesz.'
    },
    {
      question: 'Jaki hosting wybrać dla prostej strony?',
      answer: ' Od wielu lat współpracujemy z firmą dhosting.pl. Wszystkie formalności załatwiamy za Ciebie, nie musisz się martwić, że popełnisz błąd. Firma dhosting.pl oferuje nie tylko niezawodne i szybkie serwery, ale także wsparcie techniczne i pomoc w konfiguracji, co sprawia, że jest to idealne rozwiązanie dla prostych stron internetowych. Dzięki naszej współpracy z dhosting.pl możemy zapewnić Ci nie tylko hosting, ale także pełną obsługę, co pozwoli Ci skupić się na rozwijaniu swojego biznesu, mając pewność, że Twoja strona jest w dobrych rękach.'
    },
  ]
  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index)=>(
        <Div className={`cs-accordian ${selected===index?'active':''}`} key={index}>
          <Div className="cs-accordian_head" onClick={()=>handelToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>                    
            </span>
          </Div>
          <Div className='cs-accordian_body'>
            <Div className="cs-accordian_body_in">{item.answer}</Div>
            </Div>
        </Div>
      ))}
    </Div>
  )
}
