import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Linkedln',
      links: 'https://www.linkedin.com/company/mpro-pl',
    },
    {
      name: 'Facebook',
      links: 'https://www.facebook.com/mpropl',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Wzrost sprzedaży online w czasie pandemii: W wyniku pandemii COVID-19, sprzedaż w internecie znacząco wzrosła.',
      factNumber: '27,6%',
    },
    {
      title: 'W 2021 roku liczba użytkowników internetu wynosiła ponad 4,9 miliarda, co stanowi około 63% populacji światowej',
      factNumber: '63%',
    },
    {
      title: 'W 2021 roku około 73% globalnej sprzedaży e-commerce pochodziło z transakcji dokonanych za pomocą smartfonów.',
      factNumber: '73%',
    },
    {
      title: 'W 2020 roku globalna sprzedaż e-commerce wyniosła około 4,2 biliona dolarów. Prognozuje się, że do 2023 roku wartość ta wzrośnie do 6,4 biliona dolarów.',
      factNumber: '6,4',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Pokażemy ci twoje miejsce w <br/><span>e</span>-świecie"
        subtitle="Nasza firma to specjaliści w dostarczaniu wysokiej jakości usług dla segmentu e-commerce,
                  a także wdrażaniu sklepów internetowych opartych na platformach Magento i PWA Studio."
        btnText="Wyceń produkt"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Znajdziesz nas na"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl=""
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Zastanawiasz się nad wdrożeniem własnego sklepu"
          subtitle="Oto kilka faktów dotyczących wzrostu sprzedaży w internecie, które pomogą ci podjąć decyzję."
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Usługi w których możemy ci pomóc"
                subtitle="W czym możemy ci pomóc"
                btnText="Pokaż wszystkie usługi"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                    title="Projektowanie makiet"
                    link="/service/mockup"
                    src="/images/service1.svg"
                    alt="Service"
                    id="1"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                    title="Aplikacje internetowe"
                    link="/service/apps"
                    src="/images/service2.svg"
                    alt="Aplikacje internetowe"
                    id="2"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                    title="Obsługa istniejących serwisów"
                    link="/service/site-service"
                    src="/images/service3.svg"
                    alt="Obsługa istniejących serwisów"
                    id="3"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                  <Card
                      title="Optymalizacja działania sklepu"
                      link="/service/speed-site"
                      src="/images/service4.svg"
                      alt="Optymalizacja działania sklepu"
                      id="4"
                  />
                  <Spacing lg="0" md="30" />
              </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                      <Card
                          title="Moduły Magento"
                          link="/products"
                          src="/images/service6.svg"
                          alt="Moduły Magento"
                          id="5"
                      />
                      <Spacing lg="0" md="30" />
                  </Div>
                <Div className="col-lg-3 col-sm-6 mb-3">
                      <Card
                          title="Mniejsze strony - typu wizytówka"
                          link="/service/landing-page"
                          src="/images/service5.svg"
                          alt="Mniejsze strony - typu wizytówka"
                          id="5"
                      />
                      <Spacing lg="0" md="30" />
                  </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Awards Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Oto kilka powodów dlaczego powinieneś nas wybrać."
                subtitle="Dlaczego warto nas wybrać"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Awards Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Porozmawiajmy i stwórzmy <br />coś <i>fajnego</i> "
          btnText="Skontaktuj się"
          btnLink="/contact"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
