import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Optymalizacja działania sklepów');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Optymalizacja działania sklepów'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={'Optymalizacja działania sklepów'}
      />
      <Spacing lg='80' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Nasza zaawansowana oferta optymalizacji działania sklepów internetowych to klucz do sukcesu Twojego e-commerce.'
          subtitle='Optymalizacja działania sklepów'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-6">
              <IconBox
                  icon='/images/spped.png'
                  title='Optymalizacja działania sklepów'
                  subtitle='Dzięki naszej usłudze optymalizacji działania sklepów internetowych, Twój biznes e-commerce będzie działał na pełnych obrotach, przyciągając klientów, zwiększając konwersję i zwiększając zyski. Pozwól nam zająć się optymalizacją, a Ty skup się na rozwijaniu swojego biznesu!'
              />
              <Spacing lg='90' md='45'/>
          </Div>
          <Div className="col-lg-6">
              <p>Dzięki naszym specjalistycznym usługom skupionym na optymalizacji, Twój sklep zyska przewagę konkurencyjną i zapewni niezapomniane doświadczenia zakupowe dla klientów.</p>
              <Spacing lg='30' md='45'/>
              <p>Nasza oferta optymalizacji sklepów internetowych zawiera:</p>
              <ol>
                  <li>
                      <p>Pełną analizę wydajności: Nasz doświadczony zespół przeprowadzi dogłębną analizę wydajności Twojego sklepu, identyfikując wszelkie ograniczenia i potencjalne problemy, które mogą wpływać na szybkość i funkcjonalność</p>
                  </li>
                  <li>
                      <p>Indywidualne podejście: Wiemy, że każdy sklep ma swoje unikalne potrzeby. Dlatego nasza optymalizacja jest oparta na indywidualnym podejściu do Twojego sklepu, aby dostosować rozwiązania do Twoich konkretnych wymagań.</p>
                  </li>
                  <li>
                      <p>Zoptymalizowany kod: Nasz zespół programistów wykorzystuje najlepsze praktyki i technologie, aby zoptymalizować kod Twojego sklepu, poprawiając jego wydajność i responsywność.</p>
                  </li>
                  <li>
                      <p>Responsywność na różnych urządzeniach: Zapewniamy pełną responsywność Twojego sklepu na różnych urządzeniach, aby klienci mogli cieszyć się doskonałym doświadczeniem zakupowym niezależnie od tego, czy korzystają z komputera, tabletu czy smartfona.</p>
                  </li>
                  <li>
                      <p>Monitorowanie i wsparcie: Nasza praca nie kończy się na optymalizacji. Oferujemy stałe monitorowanie wydajności Twojego sklepu i pełne wsparcie techniczne w razie potrzeby.</p>
                  </li>
              </ol>
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='80' md='50'/>
      <Div className="container">
        <Cta 
          title='Masz więcej pytań? <br /> Nie zwlekaj dłużej, napisz'
          btnText='Wypełnij formularz kontaktowy'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
