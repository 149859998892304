import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Polityka prywatności | Regulamin',
      href: '/regulamin/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Projektowanie makiet',
      href: '/service/mockup'
    },
    {
      title: 'Aplikacje internetowe',
      href: '/service/apps'
    },
    {
      title: 'Optymalizacja działania sklepów',
      href: '/service/speed-site'
    },
    {
      title: 'Obsługa istniejących serwisów',
      href: '/service/site-service'
    },
    {
      title: 'Moduły Magento',
      href: '/products'
    },
    {
      title: 'Mniejsze strony',
      href: '/service/landing-page'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/footer_logo.svg' 
                  logoAlt='Logo'
                  text =''
                />
                <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Usługi'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Kontakt'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Subskrybuj'
                  subtitle='Zapisz się do naszego newslettera i bądź na bieżąco z promocjami i ofertami które przygotujemy.'
                  placeholder='twojadres@gmail.com'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2023 Mpro.pl</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant='cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
