import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Aplikacje internetowe');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Aplikacje internetowe'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={'Aplikacje internetowe'}
      />
      <Spacing lg='80' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Nasza oferta obejmuje kompleksowe usługi w zakresie tworzenia aplikacji internetowych opartych na zaawansowanej bibliotece React.'
          subtitle='Aplikacje internetowe'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-6">
              <IconBox
                  icon='/images/apps.png'
                  title='Aplikacje internetowe'
                  subtitle='Nie ważne, czy potrzebujesz nowej aplikacji internetowej od podstaw, czy modyfikacji istniejącej aplikacji, nasi specjaliści React mają wiedzę i umiejętności, aby dostarczyć Ci innowacyjne i skuteczne rozwiązania. Jesteśmy gotowi podjąć się każdego wyzwania i pomóc Ci osiągnąć sukces w internecie za pomocą naszych zaawansowanych aplikacji React.'
              />
              <Spacing lg='90' md='45'/>
          </Div>
          <Div className="col-lg-6">
              <p>Tworzymy nowoczesne i responsywne aplikacje internetowe, które zapewniają doskonałe doświadczenia użytkownikom na różnych urządzeniach.</p>
              <Spacing lg='30' md='45'/>
              <p>Nasi doświadczeni programiści React są ekspertami w tworzeniu zaawansowanych i interaktywnych interfejsów użytkownika. Oferujemy pełne wsparcie na każdym etapie projektu, od analizy wymagań i projektowania makiet po implementację, testowanie i wdrożenie aplikacji.</p>
              <Spacing lg='30' md='45'/>
              <p>Nasza oferta React obejmuje:</p>
              <ol>
                  <li>
                      <p>
                          Analizę wymagań: Dokładnie analizujemy Twoje potrzeby i cele biznesowe, aby dostosować rozwiązania do Twoich indywidualnych oczekiwań.
                      </p>
                  </li>
                  <li>
                      <p>
                          Projektowanie makiet: Tworzymy wizualne koncepcje aplikacji, które pomagają zrozumieć, jak będzie wyglądać finalny produkt.
                      </p>
                  </li>
                  <li>
                      <p>
                          Programowanie: Nasz zespół tworzy kod w oparciu o React i inne technologie, aby stworzyć responsywne i wydajne aplikacje.
                      </p>
                  </li>
                  <li>
                      <p>
                          Testowanie i optymalizacja: Przeprowadzamy testy, aby upewnić się, że aplikacja działa bez zarzutu, i dokonujemy optymalizacji, aby zapewnić jej wysoką wydajność.
                      </p>
                  </li>
                  <li>
                      <p>
                          Wdrożenie i wsparcie: Pomagamy w wdrożeniu aplikacji na serwerze oraz zapewniamy wsparcie i utrzymanie po wdrożeniu.
                      </p>
                  </li>
              </ol>
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='80' md='50'/>
      <Div className="container">
        <Cta 
          title='Masz więcej pytań? <br /> Nie zwlekaj dłużej, napisz'
          btnText='Wypełnij formularz kontaktowy'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
