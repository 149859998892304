import React from 'react'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <a href='https://www.linkedin.com/company/mpro-pl/'
         className="cs-center linkedin"
         target="_blank"
         aria-label="linkedin"
         rel="noopener noreferrer">
        <Icon icon="fa6-brands:linkedin-in" />
      </a>
      <a href='https://www.facebook.com/mpropl/'
         className="cs-center facebook"
         target="_blank"
         aria-label="facebook"
         rel="noopener noreferrer">
        <Icon icon="fa6-brands:facebook" />
      </a>
    </Div>
  )
}



