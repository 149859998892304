import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Obsługa istniejących serwisów');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Obsługa istniejących serwisów'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={'Obsługa istniejących serwisów'}
      />
      <Spacing lg='80' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Oddaj swój serwis w bezpieczne ręce.'
          subtitle='Obsługa istniejących serwisów'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-6">
              <IconBox
                  icon='/images/help.png'
                  title='Obsługa istniejących serwisów'
                  subtitle='Nasza oferta obsługi istniejących serwisów internetowych zapewnia pełne wsparcie techniczne i utrzymanie Twojej witryny, aby działała bez zarzutu, zgodnie z najwyższymi standardami.'
              />
              <Spacing lg='90' md='45'/>
          </Div>
          <Div className="col-lg-6">
              <p>Nasi doświadczeni specjaliści dbają o bezpieczeństwo, wydajność i aktualizacje Twojego serwisu, pozwalając Ci skupić się na rozwijaniu swojego biznesu, bez obaw o techniczne aspekty.</p>
              <p>Nasza oferta obsługi serwisów internetowych obejmuje:</p>
              <ol>
                  <li>
                      <p>Monitorowanie i analiza: Regularnie monitorujemy Twoją witrynę, aby szybko wykryć wszelkie potencjalne problemy i zająć się nimi przed wystąpieniem większych zakłóceń.</p>
                  </li>
                  <li>
                      <p>Aktualizacje i utrzymanie: Zapewniamy regularne aktualizacje systemu, wtyczek, szablonów i innych elementów Twojej witryny, aby zapewnić jej zgodność z najnowszymi technologiami i zwiększyć jej bezpieczeństwo.</p>
                  </li>
                  <li>
                      <p>Optymalizacja wydajności: Dążymy do osiągnięcia optymalnej wydajności Twojej witryny, zoptymalizowując kod, kompresując multimediów i wprowadzając inne optymalizacje, które skracają czas ładowania stron.</p>
                  </li>
                  <li>
                      <p>Bezpieczeństwo i ochrona: Zapewniamy kompleksowe zabezpieczenia Twojego serwisu, w tym regularne kopie zapasowe i działania anty-hakerskie, aby chronić Twoje dane i uniknąć zagrożeń związanych z cyberbezpieczeństwem.</p>
                  </li>
                  <li>
                      <p>Rozwój i modyfikacje: Oferujemy wsparcie w razie potrzeby rozwijania Twojego serwisu, wprowadzając zmiany, dodając nowe funkcje i realizując inne modyfikacje zgodnie z Twoimi potrzebami.</p>
                  </li>
                  <li>
                      <p>Pomoc techniczna: Nasz zespół jest gotowy do odpowiedzi na Twoje pytania i zapewnienia wsparcia technicznego, gdy zajdzie taka potrzeba.</p>
                  </li>
              </ol>
              <p>Zaufaj nam, aby zadbać o działanie Twojego serwisu internetowego, dzięki czemu zyskasz spokój ducha i pewność, że Twoja witryna jest w rękach profesjonalistów. Dzięki naszej ofercie obsługi serwisów, możesz skupić się na rozwijaniu swojego biznesu, wiedząc, że Twój serwis działa sprawnie i niezawodnie.</p>
              <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='80' md='50'/>
      <Div className="container">
        <Cta 
          title='Masz więcej pytań? <br /> Nie zwlekaj dłużej, napisz'
          btnText='Wypełnij formularz kontaktowy'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
