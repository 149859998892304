import React, { useRef, useId, useState } from 'react'
import Div from '../Div'
import emailjs from '@emailjs/browser';

export default function Newsletter({title, subtitle, placeholder}) {
    const [sendButtonTitle, setSendButtonTitle] = useState('Zapisz')
    const [values, setValues] = useState({
        email: '',
        agreement: 'zgoda'
    });
    const uniqueID = useId();
    const form = useRef();
    const required = () => {
        return '*'
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setSendButtonTitle('.......')

        emailjs.sendForm('service_jlts9z8', 'template_p635vq1', form.current, 'ZjeLxZatU-_ZrFSB2')
               .then((result) => {
                   setSendButtonTitle('Zapisano');
               }, (error) => {
                   setSendButtonTitle('Błąd');
               });
    };

    const handleChange = (e) => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }))
    }

    return (
        <>
            {title && <h2 className="cs-widget_title">{title}</h2>}
            <Div className="cs-newsletter_text">{subtitle}</Div>
            <Div className="cs-newsletter cs-style1">
                <form className="cs-newsletter_form"
                      ref={form}
                      onSubmit={sendEmail}>
                    <input type="email"
                           name="email"
                           className="cs-newsletter_input"
                           placeholder={placeholder + required()}
                           onChange={handleChange}
                           value={values.email}
                           required
                    />
                    <Div className="cs-newsletter-checkbox-wrapper">
                        <input type="checkbox"
                               className="cs-newsletter_input_checkbox"
                               id={uniqueID}
                               onChange={handleChange}
                               value={values.agreement}
                               name="agreement"
                               required/>
                        <label htmlFor={uniqueID}>Zezwalam na przetwarzanie danych osobowych, w celach marketingowych przez firmę MPRO <Div className="required">*</Div></label>
                    </Div>
                    <button className="cs-newsletter_btn"
                            disabled={sendButtonTitle === 'Zapisano' ? true : null}>
                        <span>{sendButtonTitle}</span>
                    </button>
                </form>
            </Div>
        </>
    )
}
