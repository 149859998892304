import { Icon }                              from '@iconify/react';
import React, { useEffect, useId, useState, useRef } from 'react';
import { pageTitle }                         from '../../helper';
import Div                                   from '../Div';
import PageHeading                           from '../PageHeading';
import SectionHeading                        from '../SectionHeading';
import Spacing                               from '../Spacing';
import ContactInfoWidget                     from '../Widget/ContactInfoWidget';
import emailjs                               from '@emailjs/browser';

export default function ContactPage() {
  pageTitle('Kontakt');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sendButtonTitle, setSendButtonTitle] = useState('Wyślij zapytanie')
  const [values, setValues] = useState({
    full_name:'',
    email: '',
    project_type: '',
    tel: '',
    message:'',
    agreement: 'tak'
  });

  const uniqueID = useId();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setSendButtonTitle('.......')

    emailjs.sendForm('service_jlts9z8', 'template_mngty2f', form.current, 'ZjeLxZatU-_ZrFSB2')
           .then((result) => {
             setSendButtonTitle('Dziękujemy');
           }, (error) => {
             setSendButtonTitle('Błąd');
           });
  };

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <>
      <PageHeading
        title="Kontakt"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Kontakt"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Zapraszamy do kontaktu"
              subtitle="Kto pyta, nie błądzi"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form  ref={form}
                   onSubmit={sendEmail}
                   className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Imię i nazwisko<Div className="required">*</Div></label>
                <input type="text"
                       name="full_name"
                       onChange={handleChange}
                       value={values.full_name}
                       className="cs-form_field"
                       required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email<Div className="required">*</Div></label>
                <input type="email"
                       name="email"
                       onChange={handleChange}
                       value={values.email}
                       className="cs-form_field"
                       required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Rodzaj projektu<Div className="required">*</Div></label>
                <input type="text"
                       name="project_type"
                       onChange={handleChange}
                       value={values.project_type}
                       className="cs-form_field"
                       required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Telefon<Div className="required">*</Div></label>
                <input type="tel"
                       name="tel"
                       onChange={handleChange}
                       value={values.tel}
                       className="cs-form_field"
                       placeholder="888 888 8888"
                       pattern="[0-9]{5}[-][0-9]{7}[-][0-9]{1}"
                       required/>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Wiadomość<Div className="required">*</Div></label>
                <textarea
                  cols="30"
                  rows="7"
                  onChange={handleChange}
                  value={values.message}
                  name="message"
                  className="cs-form_field"
                  required
               />
                <Div className="cs-newsletter-checkbox-wrapper">
                  <input type="checkbox"
                         className="cs-newsletter_input_checkbox"
                         id={uniqueID}
                         onChange={handleChange}
                         value={values.agreement}
                         name='agreement'
                         required/>
                  <label htmlFor={uniqueID}>Administratorem danych osobowych jest Tomasz Nowak prowadzący działalność Mpro Zielna 17a 59-900 Zgorzelec.
                    Dane podane w formularzu kontaktowym zostaną przetworzone w celu udzielenia odpowiedzi na wpisane zapytanie zgodnie z regulaminem.<Div className="required">*</Div>
                  </label>
                </Div>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1"
                        disabled={sendButtonTitle === 'Dziękujemy' ? true : null}>
                  <span>{sendButtonTitle}</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
