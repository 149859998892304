import React    from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a href="tel: +48 722 76 98 50" ><strong>UA EN:</strong> +48 722 76 98 50</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a href="tel: +48 693 34 32 71" ><strong>PL:</strong>  +48 693 34 32 71</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a href={"email: info@mpro.pl"}>info@mpro.pl</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          Zielna 17a, <br/>Zgorzelec, 59-900 <br/>Polska
        </li>
      </ul>
    </>
  )
}
