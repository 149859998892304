import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';

export default function TimelineSlider() {
  const timelineData = [
    [
      {
        id: '1',
        name: 'Jakość kodu',
        position: 'Przywiązujemy ogromną wagę do jakości kodu, który tworzymy. ' +
            'Jesteśmy przekonani, że wysoka jakość kodu jest kluczowa dla osiągnięcia sukcesu.',
      },
      {
        id: '2',
        name: 'Indywidualne podejście',
        position: 'Rozumiemy, że każdy klient ma swoje unikalne potrzeby, cele i wizje.',
      },
    ],
    [
      {
        id: '3',
        name: 'Innowacyjne rozwiązania',
        position: 'Kładziemy nacisk na innowacyjne rozwiązania, które umożliwiają naszym klientom osiągnięcie przewagi konkurencyjnej.',
      },
      {
        id: '4',
        name: 'Dla nas nie ma rzeczy niemożliwych.',
        position: 'Niezależnie od wyzwania, podchodzimy do niego z pełnym przekonaniem, że istnieje rozwiązanie i sposób, aby je osiągnąć.',
      },
    ]
  ];

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <Timeline columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
