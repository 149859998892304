import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage  from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage    from './components/Pages/ServiceDetailsPage';
import ServicesPage          from './components/Pages/ServicesPage';
import PortfolioPage         from './components/Pages/PortfolioPage';
import Layout                from './components/Layout';
import CaseStudyDetailsPage  from './components/Pages/CaseStudyDetailsPage';
import FaqPage               from './components/Pages/FaqPage';
import Download              from './components/Pages/Download';
import ProductGalleryDetailsPage from './components/Pages/ProductDetailsPageGallery';
import ServiceDetailsPageMockup    from './components/Pages/ServiceDetailsPageMockup';
import ServiceDetailsPageApps    from './components/Pages/ServiceDetailsPageApps';
import ServiceDetailsPageSiteSpeed    from './components/Pages/ServiceDetailsPageSiteSpeed';
import ServiceDetailsPageService from './components/Pages/ServiceDetailsPageService';
import ServiceDetailsPageLandingPage from './components/Pages/ServiceDetailsPageLandingPage';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route
              path="service/mockup"
              element={<ServiceDetailsPageMockup />}
          />
          <Route
              path="service/apps"
              element={<ServiceDetailsPageApps />}
          />
          <Route
              path="service/speed-site"
              element={<ServiceDetailsPageSiteSpeed />}
          />
          <Route
              path="service/site-service"
              element={<ServiceDetailsPageService />}
          />
          <Route
              path="service/landing-page"
              element={<ServiceDetailsPageLandingPage />}
          />
          <Route path="products" element={<PortfolioPage />} />
          <Route
            path="products/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route
              path="/products/product-details-gallery"
              element={<ProductGalleryDetailsPage />}
          />
          <Route path="contact" element={<ContactPage />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} />
          <Route path="/regulamin/" element={<Download />} />
        </Route>
        <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
