import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Projektowanie makiet');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Projektowanie makiet'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={'Projektowanie makiet'}
      />
      <Spacing lg='80' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Projektowanie makiet to sztuka wizualizacji, gdzie idee nabierają kształtu, a pomysły stają się rzeczywistością'
          subtitle='Projektowanie makiet'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-6">
              <IconBox
                  icon='/images/mockup.png'
                  title='Interaktywne elementy'
                  subtitle='Nasze makiety wyróżniają się dzięki interaktywnym elementom, które sprawiają, że użytkownicy mają wrażenie poruszania się po gotowej aplikacji już na etapie projektowania. Dzięki temu podejściu nasi klienci otrzymują realistyczną i pełniejszą wizję działania finalnego produktu.'
              />
              <Spacing lg='90' md='45'/>
          </Div>
          <Div className="col-lg-6">
              Nasza usługa projektowania makiet oferuje kompleksowe podejście do tworzenia atrakcyjnych i funkcjonalnych projektów stron internetowych oraz aplikacji. Proces projektowania makiet obejmuje trzy kluczowe etapy:
              <Spacing lg='30' md='45'/>
              <ol>
                  <li>
                      <p>Analiza i zrozumienie wymagań:
                          Rozpoczynamy od dokładnej analizy Twoich potrzeb, celów biznesowych oraz grupy docelowej Twojego projektu. Nasz zespół skupia się na pełnym zrozumieniu Twoich oczekiwań i preferencji dotyczących wyglądu, funkcji oraz interakcji na stronie. Wspólnie ustalamy koncepcję projektu, której głównym celem jest zapewnienie użytkownikom wyjątkowego i intuicyjnego doświadczenia.
                      </p>
                  </li>
                  <li>
                      <p>
                          Tworzenie wizualnych koncepcji:
                          Na podstawie zebranych informacji i wymagań, nasz doświadczony zespół projektantów tworzy wizualne koncepcje makiet. Wykorzystujemy profesjonalne narzędzia i techniki projektowania, aby stworzyć szkice, wireframe'y i prototypy, które przedstawiają układ strony, elementy graficzne, kolorystykę i inne istotne aspekty projektu. Nasze wizualne koncepcje są elastyczne i podlegają modyfikacjom w odpowiedzi na Twoje uwagi i sugestie.
                      </p>
                  </li>
                  <li>
                      <p>
                          Dopracowywanie i prezentacja:
                          Po zaakceptowaniu wizualnych koncepcji, przechodzimy do dokładnego dopracowywania makiet. Projektujemy pełne wzornictwo strony, uwzględniając wszystkie detale, takie jak typografia, ikony, animacje i efekty wizualne. Nasze projekty są responsywne, co oznacza, że dostosowują się do różnych urządzeń, zapewniając spójne i estetyczne doświadczenie użytkownikom na komputerach, tabletach i smartfonach. Ostateczne makietę przedstawiamy w formie prototypu, który pozwala na testowanie i ocenę interakcji przed właściwym wdrożeniem.
                      </p>
                  </li>
              </ol>
              <p>Nasz zespół projektowania makiet posiada nie tylko umiejętności artystyczne, ale także zdolności analizy i zrozumienia potrzeb klienta. Dzięki naszym kompleksowym rozwiązaniom, Twoja strona internetowa lub aplikacja zostaną wyposażone w atrakcyjny i funkcjonalny projekt, który zachwyci użytkowników i zapewni sukces Twojemu projektowi e-commerce.</p>
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='80' md='50'/>
      <Div className="container">
        <Cta 
          title='Masz więcej pytań? <br /> Nie zwlekaj dłużej, napisz'
          btnText='Wypełnij formularz kontaktowy'
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
